import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

interface ExportToCsvButtonProps {
  handleExportToCsv: () => void;
}

export default function ExportToCsvButton({
  handleExportToCsv,
}: ExportToCsvButtonProps) {
  const { t } = useTranslation('esg');

  return (
    <Button
      variant="outlined"
      onClick={handleExportToCsv}
      disableRipple
      startIcon={<UploadFileOutlinedIcon />}
    >
      {t('dma.exportToCsv')}
    </Button>
  );
}
